import React, { useState, useEffect } from 'react';
import sanityClient from '../sanityClient';
import imageUrlBuilder from '@sanity/image-url';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import './styles/Contact.css';
import { Helmet } from 'react-helmet';


const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    if (source && source.asset && source.asset._ref) {
      return builder.image(source);
    }
    return ''; // Or a default image URL if you have one
  }

  const Contact = ({ includeHelmet = true }) => {
  const [contactData, setContactData] = useState(null);
  const { register, handleSubmit, formState: { errors } } = useForm();

  useEffect(() => {
    sanityClient.fetch(`*[_type == "contact"]{
      backgroundImage,
      contactDetails {
        phone,
        email,
        openHours,
        address,
        info,
      },
      socialLinks,
    }`).then((data) => {
      console.log(data); // Add this line to log the data
      setContactData(data[0]);
    }).catch(console.error);
  }, []);

  const onSubmit = (data, e) => {
    emailjs.send(
      'service_33e2dox',
      'template_7syp03q',
      data,
      'n5iqEAWnJtvJ5gND_'
    ).then((result) => {
      console.log(result.text);
      toast.success('🎉 Message sent successfully! You will hear from us shortly!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      e.target.reset(); // Resets the form fields after successful submission
    }, (error) => {
      console.log(error.text);
      toast.error('Failed to send message. Please try again.');
    });
  };

  if (!contactData) {
    return <div>Loading...</div>;
  }

  return (
      <div className="contact-container" style={{ backgroundImage: `url(${urlFor(contactData.backgroundImage).url()})` }}>
        {includeHelmet && (
        <Helmet>
          <title>Contact Collazo Real Estate | Puerto Rico Realtor</title>
          <meta name="description" content="Get in touch with Collazo Real Estate for professional real estate services in Puerto Rico. Contact us for buying, selling, and property management." />
          <meta name="keywords" content="Puerto Rico real estate, Collazo Real Estate contact, Puerto Rican realtor, property management Puerto Rico, real estate services, contact real estate agent" />
        </Helmet>
      )}<div className="contact-details">
        <h2 className='contact-header'>Contact Details</h2>
        <div className="contact-info">
  <div className="info-item">
    <div className="info-icon-text">
      <i className="fas fa-phone"></i>
      <div className="info-text">
        <h3>PHONE</h3>
        <p>
        <a href={`tel:${contactData.contactDetails.phone.replace(/\s+/g, '')}`} className="telephone-link">
          {contactData.contactDetails.phone}
        </a>
      </p>
      </div>
    </div>
  </div>
  <div className="info-item">
    <div className="info-icon-text">
      <i className="fas fa-envelope"></i>
      <div className="info-text">
        <h3>EMAIL</h3>
        <p>{contactData.contactDetails.email}</p>
      </div>
    </div>
  </div>
  <div className="info-item">
    <div className="info-icon-text">
      <i className="fas fa-clock"></i>
      <div className="info-text">
        <h3>OPEN HOURS</h3>
        <p>{contactData.contactDetails.openHours}</p>
      </div>
    </div>
  </div>
  <div className="info-item">
    <div className="info-icon-text">
      <i className="fas fa-map-marker-alt"></i>
      <div className="info-text">
        <h3>ADDRESS</h3>
        <p>{contactData.contactDetails.address}</p>
      </div>
    </div>
  </div>
  <div className="info-item">
    <div className="info-icon-text">
      <i className="fas fa-plus"></i>
      <div className="info-text">
        <h3>INFO</h3>
        <p>{contactData.contactDetails.info}</p>
      </div>
    </div>
  </div>
</div>


        <div className="social-links">
          {contactData.socialLinks.map((link, index) => (
            <a key={index} href={link} target="_blank" rel="noopener noreferrer">
              <i className={`fab ${getSocialIcon(link)}`}></i>
            </a>
          ))}
        </div>
      </div>
      <div className="vertical-line"></div>
      <div className="submit-message-container">
    <h2>Submit a Message</h2>
    <form className="submit-message" onSubmit={handleSubmit(onSubmit)}>
  <input {...register('from_name')} placeholder="NAME" required />
  <input {...register('reply_to')} placeholder="EMAIL" required />
  <input {...register('phone')} placeholder="PHONE" />
  <textarea {...register('message')} placeholder="MESSAGE" required />
  <input type="submit" value="Submit" />
</form>

  </div>
    </div>
  );

};

// Helper function to determine which social icon to use based on the link
function getSocialIcon(url) {
  if (url.includes('facebook')) return 'fa-facebook-f';
  if (url.includes('twitter')) return 'fa-twitter';
  if (url.includes('instagram')) return 'fa-instagram';
  if (url.includes('linkedin')) return 'fa-linkedin-in';
  if (url.includes('youtube')) return 'fa-youtube';
  // Add more as needed
  return 'fa-link'; // Default icon
}

export default Contact;