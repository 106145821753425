import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaSearch, FaBars, FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import './styles/Navbar.css';
import logo from '../assets/logo.png';

const Navbar = () => {
  const [isNavOpen, setNavOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownItemClick = () => {
    setNavOpen(false);
    setDropdownOpen(false);
  };

  const toggleMobileDropdown = (e) => {
    e.preventDefault();
    setDropdownOpen(!isDropdownOpen);
  };

  const menuVariants = {
    opened: {
      x: 0,
      opacity: 1,
      display: 'flex',
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.1,
        stiffness: 260,
        damping: 20,
      },
    },
    closed: {
      x: '-100%',
      opacity: 0,
      transition: {
        when: 'afterChildren',
        stiffness: 260,
        damping: 20,
      },
      transitionEnd: {
        display: 'none',
      },
    },
  };

  return (
    <>
      <nav className="navbar">
        <div className="logo-container">
          <NavLink to="/" onClick={() => setNavOpen(false)}>
            <img src={logo} alt="Logo" className="logo" />
          </NavLink>
        </div>
        <div className="nav-items-desktop">
          <NavLink to="/property-search">PROPERTY SEARCH</NavLink>
          <NavLink to="/property-management">PROPERTY MANAGEMENT</NavLink>
          <div className="dropdown" 
               onMouseEnter={() => setDropdownOpen(true)} 
               onMouseLeave={() => setDropdownOpen(false)}>
            <NavLink to="/investors">RESOURCES</NavLink>
            {isDropdownOpen && (
              <div className="dropdown-content">
                <NavLink to="/calculator" onClick={handleDropdownItemClick}>RENTAL PROPERTY CALCULATOR</NavLink>
                <NavLink to="/mortgage-calculator" onClick={handleDropdownItemClick}>MORTGAGE CALCULATOR</NavLink> {/* Add this line */}
                <NavLink to="/first-time" onClick={handleDropdownItemClick}>FIRST TIME INVESTORS</NavLink>
                <NavLink to="/programs" onClick={handleDropdownItemClick}>PROGRAMS</NavLink>
              </div>
            )}
          </div>
          <NavLink to="/about-us">ABOUT US</NavLink>
        </div>
        <div className="search-icon">
          {/* <FaSearch /> */}
        </div>
        <div className="menu-icon" onClick={() => setNavOpen(!isNavOpen)}>
          <FaBars />
        </div>
      </nav>
      <AnimatePresence>
        {isNavOpen && (
          <motion.div
            className="nav-items-mobile"
            variants={menuVariants}
            initial="closed"
            animate="opened"
            exit="closed"
          >
            <NavLink to="/property-search" onClick={handleDropdownItemClick}>PROPERTY SEARCH</NavLink>
            <NavLink to="/property-management" onClick={handleDropdownItemClick}>PROPERTY MANAGEMENT</NavLink>

            <div className="dropdown">
              <div className="dropdown-toggle" onClick={toggleMobileDropdown}>
                RESOURCES
                {isDropdownOpen ? <FaCaretUp /> : <FaCaretDown />}
              </div>
              <div className={`dropdown-content ${isDropdownOpen ? "show" : ""}`}>
                <NavLink to="/calculator" onClick={handleDropdownItemClick}>RENTAL PROPERTY CALCULATOR</NavLink>
                <NavLink to="/mortgage-calculator" onClick={handleDropdownItemClick}>MORTGAGE CALCULATOR</NavLink>
                <NavLink to="/first-time" onClick={handleDropdownItemClick}>FIRST TIME INVESTORS</NavLink>
                <NavLink to="/programs" onClick={handleDropdownItemClick}>PROGRAMS</NavLink>

              </div>
            </div>
            <NavLink to="/about-us" onClick={handleDropdownItemClick}>ABOUT US</NavLink>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navbar;
