import React, { useEffect } from 'react';
import './styles/MortgageCalculator.css';
import { Helmet } from 'react-helmet';

function MortgageCalculator() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.inchcalculator.com/e/widgets.min.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="mortgage-calculator">
      <Helmet>
        <title>Mortgage Payoff Calculator for Puerto Rico | Collazo Real Estate</title>
        <meta name="description" content="Use our mortgage payoff calculator to estimate your mortgage payments and plan your financial future in Puerto Rico. Calculate interest, principal, and remaining balance." />
        <meta name="keywords" content="Puerto Rico mortgage calculator, mortgage payoff, home loan calculator, interest calculator, Puerto Rico real estate, mortgage payment calculator, home financing Puerto Rico" />
      </Helmet>
      <div id="inch-calculator-icw" data-ct="mortgage_payoff" data-cw="100%" data-ch="1350" data-cv="MTE3MTI2Mzg2NDY=">
        <div id="inch-calculator-icwh">Mortgage Payoff Calculator</div>
        <div id="inch-calculator-icwf">
          <a id="inch-calculator-icwi" href="https://www.inchcalculator.com/mortgage-payoff-calculator/" target="_blank" rel="noopener noreferrer">
            <img id="inch-calculator-icwl" src="https://cdn.inchcalculator.com/e/inch-calculator-logo-tiny.png" alt="Inch Calculator Logo" width="40" height="49" />
            <span id="inch-calculator-icwb">Inch Calculator</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default MortgageCalculator;
