import React from 'react';
import { Helmet } from 'react-helmet';
import IFrameXposure from './IFrameXposure';

function PropertySearch() {
  return (
    <div className="property-search">
      <Helmet>
        <title>Find Homes in Aguadilla, Rincon, & More | Puerto Rico Property Search</title>
        <meta name="description" content="Discover real estate listings in Aguadilla, Rincon, Isabela, and other prime Puerto Rico locations. Start your property search in Puerto Rico's top communities." />
        <meta name="keywords" content="Aguadilla real estate, Rincon homes for sale, Isabela properties, San Juan real estate, Cabo Rojo vacation homes, Culebra property, Dorado luxury homes, Mayaguez investments, Puerto Rico MLS listings, beachfront properties Puerto Rico" />
      </Helmet>
      <IFrameXposure/>
    </div>
  );
}

export default PropertySearch;
