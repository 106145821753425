import React from 'react'

import FirstTimeInvestor from './FirstTimeInvestor'

function Investors() {
  return (
    <div>
     
      <FirstTimeInvestor/>
      {/* <h1>Guides
        First Time Investors, Rental Property Calculator, Mortgage Calculator, Market Trends, External Partnerships, Resources
      </h1> */}
    </div>
  )
}

export default Investors