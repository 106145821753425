import React from 'react';
import './styles/Programs.css';
import { Helmet } from 'react-helmet';


function Programs() {



  // const pageTitle = "Homebuyer Assistance and Housing Programs in Puerto Rico | Collazo Real Estate";
  // const pageDescription = "Discover government assistance programs for homebuyers in Puerto Rico. Learn about housing incentives, tax benefits, and support for property investors.";
  // const pageKeywords = "Puerto Rico real estate, homebuyer assistance Puerto Rico, housing programs, property investment incentives, Act 60, Act 120, tax benefits, USDA programs, FHLBNY housing";

  return (
    <div className="programs-wrapper">
      <Helmet>
  <title>Homebuyer Assistance and Housing Programs in Puerto Rico | Collazo Real Estate</title>
  <meta name="description" content="Discover government assistance programs for homebuyers in Puerto Rico. Learn about housing incentives, tax benefits, and support for property investors." />
  <meta name="keywords" content="Puerto Rico real estate, homebuyer assistance Puerto Rico, housing programs, property investment incentives, Act 60, Act 120, tax benefits, USDA programs, FHLBNY housing" />
  {/* Add other relevant meta tags here */}
</Helmet>

    <nav className="breadcrumb" aria-label="Breadcrumb">
      <ol className="breadcrumb-list">
        <li className="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
          <a itemprop="item" href="/">
            <span itemprop="name">Home</span>
          </a>
          <meta itemprop="position" content="•" />
        </li>
        </ol>
      </nav>
      <div className="programs">
        <h1 className="programs-title">Homebuyer Assistance and Housing Programs in Puerto Rico</h1>
        <p className="programs-description">Explore various government programs designed to help homebuyers and property investors in Puerto Rico.</p>
        <a className="program-link" href="https://www.fhlbny.com/community/housing-programs/hdp/" target="_blank" rel="noopener noreferrer">
          FHLBNY Affordable Housing Programs
        </a>
        <a className="program-link" href="https://recuperacion.pr.gov/en/homebuyer-assistance-program/" target="_blank" rel="noopener noreferrer">
          Puerto Rico Homebuyer Assistance Program (HBA)
        </a>
        <a className="program-link" href="https://recuperacion.pr.gov/welcome/en/citizen-aid/" target="_blank" rel="noopener noreferrer">
          Citizen Aid for Homeownership in Puerto Rico
        </a>
        <a className="program-link" href="https://www.rd.usda.gov/pr" target="_blank" rel="noopener noreferrer">
          USDA Rural Development Programs in Puerto Rico
        </a>
        <a className="program-link" href="https://bvirtualogp.pr.gov/ogp/Bvirtual/leyesreferencia/PDF/2-ingles/60-2019.pdf" target="_blank" rel="noopener noreferrer">
          Puerto Rico Incentives Code Act 60/120
        </a>
      </div>
    </div>
  );
}

export default Programs;
