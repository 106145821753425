import React, { useEffect, useState } from 'react';
import sanityClient from '../sanityClient';
import imageUrlBuilder from '@sanity/image-url';
import { Helmet } from 'react-helmet';
import './styles/FirstTimeInvestor.css';

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

function FirstTimeInvestor() {
  const [investorData, setInvestorData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "firstTimeInvestor"]{
          title,
          subtitle,
          sections[]{
            number,
            label,
            description,
            image {
              asset-> {
                _id,
                url
              }
            }
          }
        }`
      )
      .then((data) => setInvestorData(data[0]))
      .catch(console.error);
  }, []);

  if (!investorData) return <div>Loading...</div>;

  return (
    <div className="investor-container">
      <Helmet>
        <title>Puerto Rico First Time Investment Guide for US Investors | Collazo Real Estate</title>
        <meta name="description" content="Unlock the potential of Puerto Rico's real estate market with our comprehensive guide for first-time investors from the United States. Learn key strategies and tips for successful property investment." />
        <meta name="keywords" content="Puerto Rico real estate, US investors, first time investment guide, property investment Puerto Rico, real estate investing tips, US investment in Puerto Rico" />
      </Helmet>
      <h1 className="investor-title">{investorData.title}</h1>
      <p className="investor-subtitle">{investorData.subtitle}</p>
      {investorData.sections.map((section, index) => (
        <div
          className={`investor-section ${index % 2 === 0 ? 'normal' : 'flipped'}`}
          key={section.number}
        >
          <div className="investor-number">{section.number}</div>
          <div className="investor-content">
            <div className="investor-text">
              <h2 className="investor-label">{section.label}</h2>
              <p className="investor-description">{section.description}</p>
            </div>
            {section.image && (
              <img className="investor-image" src={urlFor(section.image).url()} alt={`Step ${section.number}`} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default FirstTimeInvestor;
