import React from 'react';

function IFrameXposure() {
  return (
    <div style={{ height: '80vh', overflow: 'hidden' }}>
      <iframe
        src="https://properties.listingspuertorico.com/s!portalAreaLink/userId_713971"
        title="Property Listings"
        style={{ width: '100%', height: '100%', border: 'none' }}
        allowFullScreen
      >
        Your browser does not support iframes.
      </iframe>
    </div>
  );
}

export default IFrameXposure;
