import React, { useEffect } from 'react';
import './styles/RentalPropertyCalc.css';
import { Helmet } from 'react-helmet';

function RentalPropertyCalc() {
    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://cdn.inchcalculator.com/e/widgets.min.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, []);
  
    return (
      <div className="rental-property-calc">
        <Helmet>
          <title>Rental Property Calculator for Puerto Rico | Collazo Real Estate</title>
          <meta name="description" content="Use our rental property calculator to analyze your potential investments in Puerto Rico. Estimate cash flow, ROI, and other key metrics for your rental properties." />
          <meta name="keywords" content="Puerto Rico rental property calculator, investment property analysis, real estate ROI calculator, cash flow calculator, Puerto Rico real estate investment, rental yield calculator, property valuation Puerto Rico" />
        </Helmet>
        <div id="inch-calculator-icw" data-ct="rental_property" data-cw="100%" data-ch="1800" data-cv="MTE3MTE2NDU3Mzc=">
          <div id="inch-calculator-icwh">Rental Property Calculator</div>
          <div id="inch-calculator-icwf">
            <a id="inch-calculator-icwi" href="https://www.inchcalculator.com/rental-property-calculator/" target="_blank" rel="noopener noreferrer">
              <img id="inch-calculator-icwl" src="https://cdn.inchcalculator.com/e/inch-calculator-logo-tiny.png" alt="Inch Calculator Logo" width="40" height="49" />
              <span id="inch-calculator-icwb">Inch Calculator</span>
            </a>
          </div>
        </div>
      </div>
    );
}

export default RentalPropertyCalc;
