import React, { useEffect, useState } from 'react';
import sanityClient from '../sanityClient';
import imageUrlBuilder from '@sanity/image-url';
import './styles/PropertyManagement.css';
import { Helmet } from 'react-helmet';
import Contact from './Contact';

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const PropertyManagement = () => {
  const [propertyManagementData, setPropertyManagementData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "propertyManagement"]{
        title,
        description,
        includedTitle,
        includedDescription,
        headerBackground,
        steps[]{
          title,
          image,
          description
        }
      }`)
      .then((data) => setPropertyManagementData(data[0]))
      .catch(console.error);
  }, []);

  if (!propertyManagementData) {
    return <div>Loading...</div>;
  }

  return (
    <>
    <section className="property-management">
      <Helmet>
        <title>{propertyManagementData.title} | Collazo Real Estate</title>
        <meta name="description" content={propertyManagementData.description} />
        <meta name="keywords" content="Puerto Rico property management, Puerto Rico short-term rentals management, rental property, Puerto Rico real estate, vacation rentals, property investment, property services" />
        {/* Add any additional meta tags here */}
      </Helmet>
      <div
        className="property-header-background"
        style={{
          backgroundImage: `url(${urlFor(propertyManagementData.headerBackground).url()})`,
        }}
      >
        <header className="property-header">
          <h1>{propertyManagementData.title}</h1>
          <p className="property-description">{propertyManagementData.description}</p>
        </header>
      </div>
      <section className="included-section">
        <h2>{propertyManagementData.includedTitle}</h2>
        <p>{propertyManagementData.includedDescription}</p>
      </section>
      <div className="steps">
        {propertyManagementData.steps.map((step, index) => (
          <div key={index} className="step-card">
            <h3>{step.title}</h3>
            <div className="step-line" />
            {step.image && <img src={urlFor(step.image).url()} alt={step.title} />}
            <p>{step.description}</p>
          </div>
        ))}
      </div>
    </section>
    <Contact includeHelmet={false} />

    </>
  );
};

export default PropertyManagement;
