// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Homepage from "./components/Homepage";
import PropertySearch from "./components/PropertySearch";
import PropertyManagement from "./components/PropertyManagement";
import Investors from "./components/Investors";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RentalPropertyCalc from "./components/RentalPropertyCalc";
import FirstTimeInvestor from "./components/FirstTimeInvestor";
import MortgageCalculator from "./components/MortgageCalculator";
import Programs from "./components/Programs";



function App() {
  return (
    <Router>
      <Navbar />
      <ToastContainer position="top-center" autoClose={5000} />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/property-search" element={<PropertySearch />} />
        <Route path="/property-management" element={<PropertyManagement />} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/calculator" element={<RentalPropertyCalc />} />
        <Route path="/first-time" element={<FirstTimeInvestor />} />
        <Route path="/mortgage-calculator" element={<MortgageCalculator />} />
        <Route path="/programs" element={<Programs/>} />


        

      </Routes>
    </Router>
  );
}

export default App;
