import React, { useEffect, useState, useRef } from 'react';
import sanityClient from '../sanityClient';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from '@sanity/block-content-to-react';
import './styles/Homepage.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Contact from './Contact';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';



//Make links clickable and navigate to routes
const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

// Define serializers for the block content
const serializers = {
  types: {
    block: (props) => {
      // Apply different styles based on the style of the block
      let style = props.node.style || 'normal';
      if (style === 'h2') {
        return <h2 className="section-title">{props.children}</h2>;
      } else {
        // Use the new color for the description text
        return <p className="description" style={{ color: 'rgb(150, 139, 77)' }}>{props.children}</p>;
      }
    },
  },
};

const Homepage = () => {
  const [homepageData, setHomepageData] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();
  const videoRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  
  // Refs for sections
  // const gettingToKnowUsRef = useRef(null);
  const helpSectionRef = useRef(null);
  const communitiesSectionRef = useRef(null);
  // const videoSectionRef = useRef(null);


  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);


  //   gsap.fromTo(videoSectionRef.current, {
  //     scaleY: 1.5 // Adjust as desired
  // }, {
  //     scaleY: 1,
  //     scrollTrigger: {
  //         trigger: videoSectionRef.current,
  //         start: "top bottom", 
  //         toggleActions: "play reverse play reverse" ,
  //         ease: "power2.InOut"
  //     }
  // });
    // Getting To Know Us animation
    // gsap.from(gettingToKnowUsRef.current, {
    //   y: 110,
    //   opacity: 0,
    //   duration: 1,
    //   scrollTrigger: {
    //     trigger: gettingToKnowUsRef.current,
    //     start: "top 80%", 
    //     toggleActions: "play none none reverse" 
    //   }
    // });

    // Help section animation
    gsap.from(helpSectionRef.current, {
      x: -110,
      opacity: 0,
      duration: 1,
      scrollTrigger: {
        trigger: helpSectionRef.current,
        start: "top 75%",
        toggleActions: "play none none reverse" 
      }
    });

    // Communities section (adjust as needed)
     // Inside your useEffect with other GSAP animations
  gsap.from('.community-item', {
    y: 50, 
    opacity: 0,
    duration: 0.8,
    stagger: 0.2, // Delay between each item's animation
    scrollTrigger: {
      trigger: communitiesSectionRef.current,
      start: "top 80%",
      toggleActions: "play none none reverse" 
    }
  });


  }, []); 


  const handleCommunityClick = () => {
    navigate('/property-search');
  };

  const handleContactClick = () => {
    navigate("/contact");
  };

  const serializers = {
    types: {
      block: (props) => {
        // Get the block style
        let style = props.node.style || 'normal';
  
        // Determine if it's the first block
        const isFirstBlock = props.node._key === homepageData.gettingToKnowUs.description[0]._key;
        
        // Apply the gold color to the first block, and black to the others
        const color = isFirstBlock ? 'rgb(150, 139, 77)' : 'rgb(0, 0, 0)';
  
        // If it's a normal block, render a paragraph with the determined color
        return <p style={{ color: color }}>{props.children}</p>;
      },
    },
  };
  

useEffect(() => {
  sanityClient
    .fetch(`*[_type == "homepage"]{
        videoSection {
          backgroundVideo {
            asset-> {
              _id,
              url
            }
          },
          backgroundMobileImage {
            asset-> {
              _id,
              url
            }
          },
          videoCaption
        },
        gettingToKnowUs {
          title,
          description,
          description2,
          image {
            asset-> {
              _id,
              url
            }
          }
        },
        helpSection[]{
          title,
          description,
          image {
            asset-> {
              _id,
              url
            }
          }, 
          link
        },
        communitiesSection[]{
          communityName,
          description,
          image {
            asset-> {
              _id,
              url
            }
          }
        }

      }`)
    .then((data) => {
      setHomepageData(data[0]);
    })
    .catch(console.error);
}, []);

  

  if (!homepageData) {
    return <div>Loading...</div>;
  }

  const { backgroundVideo, backgroundMobileImage, videoCaption } = homepageData.videoSection;

  return (
    <div className="homepage">
      <Helmet>
        <title>Discover Puerto Rico's Finest Communities - Your Real Estate Guide</title>
        <meta name="description" content="Explore real estate in Puerto Rico's most sought-after communities: Aguadilla, Isabela, Rincon, and more. Find your dream home or the perfect investment opportunity." />
        <meta name="keywords" content="Puerto Rico real estate, Aguadilla homes, Isabela properties, Rincon real estate, San Juan investments, Cabo Rojo vacation homes, San Sebastian, Culebra, Dorado, Mayaguez" />
        {/* Additional metadata */}
      </Helmet>
      {isMobile && backgroundMobileImage ? (
  <div className="video-section">
    <img
      src={backgroundMobileImage.asset.url}
      alt="Mobile Fallback"
      className="mobile-video-fallback"
      style={{ display: 'block' }} // Ensure it is displayed
    />
    <h2>{videoCaption}</h2>
  </div>
) : (
  <div className="video-section">
    <video
      ref={videoRef}
      src={backgroundVideo.asset.url}
      autoPlay
      loop
      muted
      style={{ display: 'block' }} // Ensure it is displayed
    />
    <h2>{videoCaption}</h2>
  </div>
)}



{/* Getting To Know Us Section */}
{homepageData.gettingToKnowUs && (
  <section className="getting-to-know-us">
    <div className="content">
      <h2 className="section-title">{homepageData.gettingToKnowUs.title}</h2>
      {/* Render the first description with gold color */}
      <BlockContent 
        blocks={homepageData.gettingToKnowUs.description}
        serializers={serializers}
      />
      {/* Render the second description with black color */}
      {homepageData.gettingToKnowUs.description2 && (
        <BlockContent 
          blocks={homepageData.gettingToKnowUs.description2}
          serializers={serializers}
        />
      )}
      <button className="contact-button" onClick={handleContactClick}>Contact Us</button>
    </div>
    <img
      src={urlFor(homepageData.gettingToKnowUs.image).url()}
      alt="About Us"
    />
  </section>
)}



<div className="help-section-container" ref={helpSectionRef}>
  <h2 className="help-section-title">HOW CAN WE HELP YOU?</h2>
  <div className="help-section" >
  {homepageData.helpSection.map((item, index) => (
    <Link to={item.link} key={index} className="help-item">
      <div className="help-item-image">
        <img src={urlFor(item.image).url()} alt={item.title} />
      </div>
      <div className="help-item-banner">
        <h3>{item.title}</h3>
      </div>
    </Link>
  ))}
</div>

</div>



{homepageData.communitiesSection && (
  <div className="communities-section" ref={communitiesSectionRef}>
    {homepageData.communitiesSection.map((community, index) => (
      <div key={index} className="community-item" onClick={handleCommunityClick}>
        <img src={urlFor(community.image).url()} alt={community.communityName} />
        <h3>{community.communityName}</h3>
        <p>{community.description}</p>
        {/* <img src={urlFor(community.image).url()} alt={community.communityName} /> */}
      </div>
    ))}
  </div>
)}


<Contact includeHelmet={false} />
 </div>
  );
};

export default Homepage;